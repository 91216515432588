import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Optional, Inject, EventEmitter, Component, ChangeDetectionStrategy, ViewEncapsulation, ViewChild, Input, Output, HostListener, APP_INITIALIZER, NgModule } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { CommonModule } from '@angular/common';
const _c0 = ["editor"];
const EDITOR_SETTINGS = new InjectionToken('EDITOR_SETTINGS');
class CodeEditorService {
  constructor(settings) {
    this.baseUrl = 'https://cdn.jsdelivr.net/npm/monaco-editor/min';
    this.typingsWorkerUrl = 'https://cdn.jsdelivr.net/npm/@ngstack/code-editor/workers/typings-worker.js';
    this.typingsLoaded = new Subject();
    this.loaded = new BehaviorSubject(null);
    this.loadingTypings = new BehaviorSubject(false);
    const defaults = {
      baseUrl: this.baseUrl,
      typingsWorkerUrl: this.typingsWorkerUrl,
      ...settings
    };
    this.baseUrl = defaults.baseUrl;
    this.typingsWorkerUrl = defaults.typingsWorkerUrl;
  }
  loadTypingsWorker() {
    if (!this.typingsWorker && window.Worker) {
      if (this.typingsWorkerUrl.startsWith('http')) {
        const proxyScript = `importScripts('${this.typingsWorkerUrl}');`;
        const proxy = URL.createObjectURL(new Blob([proxyScript], {
          type: 'text/javascript'
        }));
        this.typingsWorker = new Worker(proxy);
      } else {
        this.typingsWorker = new Worker(this.typingsWorkerUrl);
      }
      this.typingsWorker.addEventListener('message', e => {
        this.loadingTypings.next(false);
        this.typingsLoaded.next(e.data);
      });
    }
    return this.typingsWorker;
  }
  loadTypings(dependencies) {
    if (dependencies && dependencies.length > 0) {
      const worker = this.loadTypingsWorker();
      if (worker) {
        this.loadingTypings.next(true);
        worker.postMessage({
          dependencies
        });
      }
    }
  }
  loadEditor() {
    return new Promise(resolve => {
      const onGotAmdLoader = () => {
        window.require.config({
          paths: {
            vs: `${this.baseUrl}/vs`
          }
        });
        if (this.baseUrl.startsWith('http')) {
          const proxyScript = `
            self.MonacoEnvironment = {
              baseUrl: "${this.baseUrl}"
            };
            importScripts('${this.baseUrl}/vs/base/worker/workerMain.js');
          `;
          const proxy = URL.createObjectURL(new Blob([proxyScript], {
            type: 'text/javascript'
          }));
          window['MonacoEnvironment'] = {
            getWorkerUrl: function () {
              return proxy;
            }
          };
        }
        window.require(['vs/editor/editor.main'], () => {
          this.loaded.next({
            monaco
          });
          resolve();
        });
      };
      if (!window.require) {
        const loaderScript = document.createElement('script');
        loaderScript.type = 'text/javascript';
        loaderScript.src = `${this.baseUrl}/vs/loader.js`;
        loaderScript.addEventListener('load', onGotAmdLoader);
        document.body.appendChild(loaderScript);
      } else {
        onGotAmdLoader();
      }
    });
  }
}
CodeEditorService.ɵfac = function CodeEditorService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CodeEditorService)(i0.ɵɵinject(EDITOR_SETTINGS, 8));
};
CodeEditorService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: CodeEditorService,
  factory: CodeEditorService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CodeEditorService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [EDITOR_SETTINGS]
      }]
    }];
  }, null);
})();
class TypescriptDefaultsService {
  constructor(codeEditorService) {
    codeEditorService.loaded.subscribe(event => {
      this.setup(event.monaco);
    });
    codeEditorService.typingsLoaded.subscribe(typings => {
      this.updateTypings(typings);
    });
  }
  setup(monaco) {
    if (!monaco) {
      return;
    }
    this.monaco = monaco;
    const defaults = monaco.languages.typescript.typescriptDefaults;
    defaults.setCompilerOptions({
      target: monaco.languages.typescript.ScriptTarget.ES6,
      module: 'commonjs',
      noEmit: true,
      noLib: true,
      emitDecoratorMetadata: true,
      experimentalDecorators: true,
      allowNonTsExtensions: true,
      declaration: true,
      lib: ['es2017', 'dom'],
      baseUrl: '.',
      paths: {}
    });
    defaults.setMaximumWorkerIdleTime(-1);
    defaults.setEagerModelSync(true);
    /*
    defaults.setDiagnosticsOptions({
      noSemanticValidation: true,
      noSyntaxValidation: true
    });
    */
  }
  updateTypings(typings) {
    if (typings) {
      this.addExtraLibs(typings.files);
      this.addLibraryPaths(typings.entryPoints);
    }
  }
  addExtraLibs(libs = []) {
    if (!this.monaco || !libs || libs.length === 0) {
      return;
    }
    const defaults = this.monaco.languages.typescript.typescriptDefaults;
    // undocumented API
    const registeredLibs = defaults.getExtraLibs();
    libs.forEach(lib => {
      if (!registeredLibs[lib.path]) {
        // needs performance improvements, recreates its worker each time
        // defaults.addExtraLib(lib.content, lib.path);
        // undocumented API
        defaults._extraLibs[lib.path] = lib.content;
      }
    });
    // undocumented API
    defaults._onDidChange.fire(defaults);
  }
  addLibraryPaths(paths = {}) {
    if (!this.monaco) {
      return;
    }
    const defaults = this.monaco.languages.typescript.typescriptDefaults;
    const compilerOptions = defaults.getCompilerOptions();
    compilerOptions.paths = compilerOptions.paths || {};
    Object.keys(paths).forEach(key => {
      compilerOptions.paths[key] = [paths[key]];
    });
  }
}
TypescriptDefaultsService.ɵfac = function TypescriptDefaultsService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TypescriptDefaultsService)(i0.ɵɵinject(CodeEditorService));
};
TypescriptDefaultsService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TypescriptDefaultsService,
  factory: TypescriptDefaultsService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TypescriptDefaultsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: CodeEditorService
    }];
  }, null);
})();
class JavascriptDefaultsService {
  constructor(codeEditorService) {
    codeEditorService.loaded.subscribe(event => {
      this.setup(event.monaco);
    });
    codeEditorService.typingsLoaded.subscribe(typings => {
      this.updateTypings(typings);
    });
  }
  setup(monaco) {
    if (!monaco) {
      return;
    }
    this.monaco = monaco;
    const defaults = monaco.languages.typescript.javascriptDefaults;
    defaults.setCompilerOptions({
      target: monaco.languages.typescript.ScriptTarget.ES6,
      module: 'commonjs',
      allowNonTsExtensions: true,
      baseUrl: '.',
      paths: {}
    });
    defaults.setMaximumWorkerIdleTime(-1);
    defaults.setEagerModelSync(true);
    /*
    defaults.setDiagnosticsOptions({
      noSemanticValidation: false,
      noSyntaxValidation: false
    });
    */
  }
  updateTypings(typings) {
    if (typings) {
      this.addExtraLibs(typings.files);
      this.addLibraryPaths(typings.entryPoints);
    }
  }
  addExtraLibs(libs = []) {
    if (!this.monaco || !libs || libs.length === 0) {
      return;
    }
    const defaults = this.monaco.languages.typescript.javascriptDefaults;
    // undocumented API
    const registeredLibs = defaults.getExtraLibs();
    libs.forEach(lib => {
      if (!registeredLibs[lib.path]) {
        // needs performance improvements, recreates its worker each time
        // defaults.addExtraLib(lib.content, lib.path);
        // undocumented API
        defaults._extraLibs[lib.path] = lib.content;
      }
    });
    // undocumented API
    defaults._onDidChange.fire(defaults);
  }
  addLibraryPaths(paths = {}) {
    if (!this.monaco) {
      return;
    }
    const defaults = this.monaco.languages.typescript.javascriptDefaults;
    const compilerOptions = defaults.getCompilerOptions();
    compilerOptions.paths = compilerOptions.paths || {};
    Object.keys(paths).forEach(key => {
      compilerOptions.paths[key] = [paths[key]];
    });
  }
}
JavascriptDefaultsService.ɵfac = function JavascriptDefaultsService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || JavascriptDefaultsService)(i0.ɵɵinject(CodeEditorService));
};
JavascriptDefaultsService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: JavascriptDefaultsService,
  factory: JavascriptDefaultsService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(JavascriptDefaultsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: CodeEditorService
    }];
  }, null);
})();
class JsonDefaultsService {
  constructor(codeEditorService) {
    codeEditorService.loaded.subscribe(event => {
      this.setup(event.monaco);
    });
  }
  setup(monaco) {
    if (!monaco) {
      return;
    }
    this.monaco = monaco;
    const defaults = monaco.languages.json.jsonDefaults;
    defaults.setDiagnosticsOptions({
      validate: true,
      allowComments: true,
      schemas: [...defaults._diagnosticsOptions.schemas, {
        uri: 'http://myserver/foo-schema.json',
        // fileMatch: [id],
        // fileMatch: ['*.json'],
        schema: {
          type: 'object',
          properties: {
            p1: {
              enum: ['v1', 'v2']
            },
            p2: {
              $ref: 'http://myserver/bar-schema.json'
            }
          }
        }
      }, {
        uri: 'http://myserver/bar-schema.json',
        // fileMatch: [id],
        // fileMatch: ['*.json'],
        schema: {
          type: 'object',
          properties: {
            q1: {
              enum: ['x1', 'x2']
            }
          }
        }
      }]
    });
  }
  addSchemas(id, definitions = []) {
    const defaults = this.monaco.languages.json.jsonDefaults;
    const options = defaults.diagnosticsOptions;
    const schemas = {};
    if (options && options.schemas && options.schemas.length > 0) {
      options.schemas.forEach(schema => {
        schemas[schema.uri] = schema;
      });
    }
    for (const {
      uri,
      schema
    } of definitions) {
      schemas[uri] = {
        uri,
        schema,
        fileMatch: [id || '*.json']
      };
    }
    // console.log(schemas);
    // console.log(Object.values(schemas));
    options.schemas = Object.values(schemas);
    defaults.setDiagnosticsOptions(options);
  }
}
JsonDefaultsService.ɵfac = function JsonDefaultsService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || JsonDefaultsService)(i0.ɵɵinject(CodeEditorService));
};
JsonDefaultsService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: JsonDefaultsService,
  factory: JsonDefaultsService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(JsonDefaultsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: CodeEditorService
    }];
  }, null);
})();
class CodeEditorComponent {
  constructor(editorService, typescriptDefaults, javascriptDefaults, jsonDefaults) {
    this.editorService = editorService;
    this.typescriptDefaults = typescriptDefaults;
    this.javascriptDefaults = javascriptDefaults;
    this.jsonDefaults = jsonDefaults;
    // private _value = '';
    this.defaultOptions = {
      lineNumbers: true,
      contextmenu: false,
      minimap: {
        enabled: false
      }
    };
    // @Input()
    // set value(v: string) {
    //   if (v !== this._value) {
    //     this._value = v;
    //     this.setEditorValue(v);
    //     this.valueChanged.emit(v);
    //   }
    // }
    // get value(): string {
    //   return this._value;
    // }
    /**
     * Editor theme. Defaults to `vs`.
     *
     * Allowed values: `vs`, `vs-dark` or `hc-black`.
     * @memberof CodeEditorComponent
     */
    this.theme = 'vs';
    /**
     * Editor options.
     *
     * See https://microsoft.github.io/monaco-editor/api/interfaces/monaco.editor.ieditorconstructionoptions.html for more details.
     *
     * @memberof CodeEditorComponent
     */
    this.options = {};
    /**
     * Toggle readonly state of the editor.
     *
     * @memberof CodeEditorComponent
     */
    this.readOnly = false;
    this.valueChanged = new EventEmitter();
    this.loaded = new EventEmitter();
  }
  ngOnDestroy() {
    if (this._editor) {
      this._editor.dispose();
      this._editor = null;
    }
    if (this._model) {
      this._model.dispose();
      this._model = null;
    }
  }
  ngOnChanges(changes) {
    if (changes.codeModel && !changes.codeModel.firstChange) {
      this.updateModel(changes.codeModel.currentValue);
    }
    if (changes.readOnly && !changes.readOnly.firstChange) {
      if (this._editor) {
        this._editor.updateOptions({
          readOnly: changes.readOnly.currentValue
        });
      }
    }
    if (changes.theme && !changes.theme.firstChange) {
      monaco.editor.setTheme(changes.theme.currentValue);
    }
  }
  onResize() {
    if (this._editor) {
      this._editor.layout();
    }
  }
  async ngAfterViewInit() {
    this.setupEditor();
    this.loaded.emit();
  }
  setupEditor() {
    const domElement = this.editorContent.nativeElement;
    const settings = {
      value: '',
      language: 'text',
      uri: `code-${Date.now()}`,
      ...this.codeModel
    };
    this._model = monaco.editor.createModel(settings.value, settings.language, monaco.Uri.file(settings.uri));
    const options = Object.assign({}, this.defaultOptions, this.options, {
      readOnly: this.readOnly,
      theme: this.theme,
      model: this._model
    });
    this._editor = monaco.editor.create(domElement, options);
    this._model.onDidChangeContent(( /*e*/
    ) => {
      const newValue = this._model.getValue();
      if (this.codeModel) {
        this.codeModel.value = newValue;
      }
      this.valueChanged.emit(newValue);
    });
    this.setupDependencies(this.codeModel);
  }
  setupDependencies(model) {
    if (!model) {
      return;
    }
    const {
      language
    } = model;
    if (language) {
      const lang = language.toLowerCase();
      switch (lang) {
        case 'typescript':
          if (model.dependencies) {
            this.editorService.loadTypings(model.dependencies);
          }
          break;
        case 'javascript':
          if (model.dependencies) {
            this.editorService.loadTypings(model.dependencies);
          }
          break;
        case 'json':
          if (model.schemas) {
            this.jsonDefaults.addSchemas(model.uri, model.schemas);
          }
          break;
        default:
          break;
      }
    }
  }
  setEditorValue(value) {
    // Fix for value change while dispose in process.
    setTimeout(() => {
      if (this._model) {
        this._model.setValue(value);
      }
    });
  }
  updateModel(model) {
    if (model) {
      this.setEditorValue(model.value);
      if (this._model && typeof monaco !== undefined) {
        monaco.editor.setModelLanguage(this._model, model.language);
      }
      this.setupDependencies(model);
    }
  }
}
CodeEditorComponent.ɵfac = function CodeEditorComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CodeEditorComponent)(i0.ɵɵdirectiveInject(CodeEditorService), i0.ɵɵdirectiveInject(TypescriptDefaultsService), i0.ɵɵdirectiveInject(JavascriptDefaultsService), i0.ɵɵdirectiveInject(JsonDefaultsService));
};
CodeEditorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: CodeEditorComponent,
  selectors: [["ngs-code-editor"]],
  viewQuery: function CodeEditorComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 7);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.editorContent = _t.first);
    }
  },
  hostAttrs: [1, "ngs-code-editor"],
  hostBindings: function CodeEditorComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("resize", function CodeEditorComponent_resize_HostBindingHandler() {
        return ctx.onResize();
      }, false, i0.ɵɵresolveWindow);
    }
  },
  inputs: {
    codeModel: "codeModel",
    theme: "theme",
    options: "options",
    readOnly: "readOnly"
  },
  outputs: {
    valueChanged: "valueChanged",
    loaded: "loaded"
  },
  features: [i0.ɵɵNgOnChangesFeature],
  decls: 2,
  vars: 0,
  consts: [["editor", ""], ["id", "editor", 1, "monaco-editor", "editor"]],
  template: function CodeEditorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelement(0, "div", 1, 0);
    }
  },
  styles: [".editor{width:100%;height:inherit;min-height:200px}\n"],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CodeEditorComponent, [{
    type: Component,
    args: [{
      selector: 'ngs-code-editor',
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'ngs-code-editor'
      },
      template: "<div id=\"editor\" #editor class=\"monaco-editor editor\"></div>\n",
      styles: [".editor{width:100%;height:inherit;min-height:200px}\n"]
    }]
  }], function () {
    return [{
      type: CodeEditorService
    }, {
      type: TypescriptDefaultsService
    }, {
      type: JavascriptDefaultsService
    }, {
      type: JsonDefaultsService
    }];
  }, {
    editorContent: [{
      type: ViewChild,
      args: ['editor', {
        static: true
      }]
    }],
    codeModel: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    readOnly: [{
      type: Input
    }],
    valueChanged: [{
      type: Output
    }],
    loaded: [{
      type: Output
    }],
    onResize: [{
      type: HostListener,
      args: ['window:resize']
    }]
  });
})();
function setupEditorService(service) {
  const result = () => service.loadEditor();
  return result;
}
class CodeEditorModule {
  static forRoot(settings) {
    return {
      ngModule: CodeEditorModule,
      providers: [{
        provide: EDITOR_SETTINGS,
        useValue: settings
      }, CodeEditorService, TypescriptDefaultsService, JavascriptDefaultsService, JsonDefaultsService, {
        provide: APP_INITIALIZER,
        useFactory: setupEditorService,
        deps: [CodeEditorService],
        multi: true
      }]
    };
  }
  static forChild() {
    return {
      ngModule: CodeEditorModule
    };
  }
}
CodeEditorModule.ɵfac = function CodeEditorModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || CodeEditorModule)();
};
CodeEditorModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: CodeEditorModule
});
CodeEditorModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CodeEditorModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [CodeEditorComponent],
      exports: [CodeEditorComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of code-editor
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CodeEditorComponent, CodeEditorModule, CodeEditorService, EDITOR_SETTINGS, JavascriptDefaultsService, TypescriptDefaultsService, setupEditorService };
